<template>
  <div class="marker-wrapper">
    <el-card class="st-card">
      <h3>机构列表</h3>

      <div class="st-search">
      </div>

      <el-table
        :data="marketItems"
        border
        class="st-table-cols"
        style="width: 100%">
        <el-table-column
          prop="fullName"
          label="机构名称">
        </el-table-column>
        <el-table-column
          prop="EnterpriseInfo"
          label="工商信息">
          <template slot-scope="scope">
            <p>
              公司名称：
              <span>{{ scope.row.EnterpriseInfo.name }}</span>
            </p>
            <p>
              社会代码：
              <span>{{ scope.row.EnterpriseInfo.tax }}</span>
            </p>
            <p>
              法人：
              <span>{{ scope.row.owner }}</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="contact"
          label="负责人信息">
          <template slot-scope="scope">
            <p>
              联系人：
              <span>{{ scope.row.contact[0].name }}</span>
            </p>
            <p class="item-interval">
              联系方式：
              <span>{{ scope.row.contact[0].mobile }}</span>
              <span>{{ scope.row.contact[0].email }}</span>
            </p>
          </template>
        </el-table-column>
      </el-table>

      <div class="st-pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          background
          :page-size="10"
          layout="total, prev, pager, next"
          :total="marketCount">
        </el-pagination>
      </div>

    </el-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: '',
  data () {
    return {
      formData: {},

      // 页码
      currentPage: 1
    }
  },
  computed: {
    ...mapState(['marketCount', 'marketItems'])
  },
  mounted () {
    this.getMarketItems()
  },
  methods: {
    getMarketItems () {
      this.$store.dispatch('getMarketItems', {
        page: this.currentPage,
        limit: 10,
        businessType: 6
      })
    },

    // 切换页码
    handleCurrentChange(val) {
      this.currentPage = val
      this.getMarketItems()
    }
  }
}
</script>


<style lang="scss" scoped>
  @import "../../../../assets/css/base";
</style>
